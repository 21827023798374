import { Injectable } from '@angular/core';
import { PoserQuestionModel } from '../../../models/faq/poser-question.model';
import { of, Observable, Observer } from 'rxjs';
import { CategorieQuestionModel } from '../../../models/faq/categorie-question.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PoserQuestionService {
  public question: PoserQuestionModel;
  public categoriesQuestion: Array<CategorieQuestionModel> = [];
  public baseUrl = 'http://vps503739.ovh.net/back-app-abichid/web/api/';
  constructor(private httpClient: HttpClient) { }

  public poserQuestion(form): Observable<Array<any>> {
    console.log(form);
    return this.httpClient.post<Array<any>>(this.baseUrl + 'poser-question', form);
  }
  public rechercheCategorieQuestion(id: string = null): Observable<Array<CategorieQuestionModel>> {
    return Observable.create(
      (observer: Observer<Array<CategorieQuestionModel>>) => {

        if (!this.question.categorie1) {
          this.categoriesQuestion = this.getFakeCategorieQuestion1();
          observer.next(this.categoriesQuestion);
        }
        else if (!this.question.categorie2) {
          observer.next(this.getCategorie2(this.categoriesQuestion, this.question.categorie1));
        }
        else if (!this.question.categorie3) {
          observer.next(this.getCategorie2(this.getCategorie2(this.categoriesQuestion, this.question.categorie1), this.question.categorie2));
        }
        else {
          observer.error(false);
        }
        observer.complete();
      }
    )


  }

  private getCategorie2(categories: Array<CategorieQuestionModel>, categorieAcomparer: string): Array<CategorieQuestionModel> {
    console.log(categories)
    for (let categorie in categories) {
      if (categories[categorie].id == categorieAcomparer) {
        return categories[categorie].categorieEnfant;
      }
    }
  }

  // fakeData
  private getFakeCategorieQuestion1(): Array<CategorieQuestionModel> {
    return [
      {
        id: "0",
        libelle: "Aucune catégorie correspondante",
      },
      {
        id: "1",
        libelle: "chabath",
        categorieEnfant: [
          {
            id: "0",
            libelle: "Aucune catégorie correspondante",
          },
          {

            id: "2",
            libelle: "repas de chabath",
            categorieEnfant: [
              {
                id: "0",
                libelle: "Aucune catégorie correspondante",
              },
              {
                id: "6",
                libelle: "plata"
              },
              {
                id: "7",
                libelle: "salade",

              }]
          },
          {
            id: "3",
            libelle: "Tefila",
            categorieEnfant: [
              {
                id: "0",
                libelle: "Aucune catégorie correspondante",
              },
              {
                id: "9",
                libelle: "Chaharit"
              },
              {
                id: "10",
                libelle: "minha",

              }]
          },
          {
            id: "12",
            libelle: "mouksté",

          }

        ]
      },
      {
        id: "2",
        libelle: "Cacherouth"
      }
    ];
  }
}
